import * as LucideIcons from 'lucide-react';
import { LucideProps } from 'lucide-react';

const withTailwindClasses = (IconComponent: any) =>
{
	return ({ className, ...props }: LucideProps & { className?: string }) =>
	{
		const sizeMap: { [key: string]: number } = {
			'text-icon': 24,
			'text-icon-sm': 20,
			'text-icon-lg': 28,
			'text-icon-xl': 32,
			'text-icon-2xl': 40,
			'text-icon-3xl': 48
		};

		const colorMap: { [key: string]: string } = {
			'text-neutral-50': '#fafafa',
			'text-neutral-100': '#f5f5f5',
			'text-neutral-200': '#e5e5e5',
			'text-neutral-300': '#d4d4d4',
			'text-neutral-400': '#a3a3a3',
			'text-neutral-500': '#737373',
			'text-neutral-600': '#525252',
			'text-neutral-700': '#404040',
			'text-neutral-800': '#262626',
			'text-neutral-900': '#171717',
			'text-maven-highlight': '#baa2f6',
			'text-maven-error-50': '#FFEEEE',
			'text-maven-error-100': '#FACDCD',
			'text-maven-error-200': '#F29B9B',
			'text-maven-error-300': '#E66A6A',
			'text-maven-error-400': '#D64545',
			'text-maven-error-500': '#BA2525',
			'text-maven-error-600': '#A61B1B',
			'text-maven-error-700': '#911111',
			'text-maven-error-800': '#780A0A',
			'text-maven-error-900': '#610404',
			'text-maven-indicator-50': '#fffaeb',
			'text-maven-indicator-100': '#fcefc7',
			'text-maven-indicator-200': '#f8e3a3',
			'text-maven-indicator-300': '#f9da8b',
			'text-maven-indicator-400': '#f7d070',
			'text-maven-indicator-500': '#e9b949',
			'text-maven-indicator-600': '#c99a2e',
			'text-maven-indicator-700': '#a27c1a',
			'text-maven-indicator-800': '#7c5e10',
			'text-maven-indicator-900': '#513c06',
			'text-maven-primary-50': '#F7FDFB',
			'text-maven-primary-100': '#D4F3E9',
			'text-maven-primary-200': '#ABE7D5',
			'text-maven-primary-300': '#81DBC0',
			'text-maven-primary-400': '#56CEAA',
			'text-maven-primary-500': '#2CC295',
			'text-maven-primary-600': '#239B77',
			'text-maven-primary-700': '#1A7459',
			'text-maven-primary-800': '#124E3C',
			'text-maven-primary-900': '#09281E',
			'text-maven-accent-50': '#F8F7FA',
			'text-maven-accent-100': '#DDD7E6',
			'text-maven-accent-200': '#BCB0CE',
			'text-maven-accent-300': '#9B89B6',
			'text-maven-accent-400': '#79619D',
			'text-maven-accent-500': '#6E47A6',
			'text-maven-accent-600': '#583985',
			'text-maven-accent-700': '#422B64',
			'text-maven-accent-800': '#2C1D43',
			'text-maven-accent-900': '#160E21'
		};

		// Find size class in the className prop
		const sizeClass = className?.split(' ').find(cls => Object.keys(sizeMap).includes(cls));
		// Find color class in the className prop
		const colorClass = className?.split(' ').find(cls => Object.keys(colorMap).includes(cls));

		// Use the mapped values if classes are found, otherwise use currentSize/currentColor for inheritance
		const size = sizeClass ? sizeMap[sizeClass] : 24;
		const color = colorClass ? colorMap[colorClass] : 'currentColor';

		return <IconComponent size={size} color={color} className={className} {...props} />;
	};
};

// Wrap and export all icons with their original names
const WrappedIcons: any = {}; //{ [key: string]: React.ElementType } = {};

Object.keys(LucideIcons).forEach(iconName =>
{
	WrappedIcons[iconName] = withTailwindClasses((LucideIcons as any)[iconName]);
});

export const {
	Plus,
	PlusCircle,
	Columns,
	MessageSquarePlus,
	ChevronLeft,
	ArrowRight,
	ChevronRight,
	ArrowLeft,
	ArrowUpRight,
	FileText,
	Paperclip,
	List,
	Rewind,
	BarChart,
	Zap,
	Book,
	BookOpen,
	BookmarkPlus,
	Pen,
	Newspaper,
	PieChart,
	Calendar,
	CalendarIcon,
	X,
	Target,
	Crosshair,
	MessageCircle,
	Check,
	CheckCircle,
	Square,
	SquareSlash,
	CheckSquare,
	ClipboardCheck,
	Circle,
	XCircle,
	Users,
	PanelLeftClose,
	Building,
	Copy,
	ClipboardX,
	Building2,
	AlertTriangle,
	RotateCw,
	Database,
	Delete,
	Code,
	Monitor,
	CircleDot,
	Download,
	GripHorizontal,
	GripVertical,
	Edit,
	FileEdit,
	Mail,
	AlertOctagon,
	Expand,
	PanelRightOpen,
	PanelLeftOpen,
	ChevronUp,
	ChevronDown,
	FileOutput,
	File,
	Filter,
	FilterX,
	Flag,
	FileSpreadsheet,
	Paintbrush,
	Forward,
	Landmark,
	HelpCircle,
	Hexagon,
	History,
	GraduationCap,
	Hourglass,
	Info,
	FileCode,
	MoveDown,
	Keyboard,
	Tag,
	FlaskConical,
	Image,
	Lightbulb,
	LinkIcon,
	Link2Off,
	ListOrdered,
	MapPin,
	Lock,
	LogOut,
	UsersRound,
	Bell,
	ExternalLink,
	Pause,
	Clock,
	Pentagon,
	User,
	Pill,
	Play,
	ListPlus,
	ListMinus,
	FilePlus,
	Eye,
	Printer,
	ListTodo,
	Loader,
	MessageSquareMore,
	Record,
	Quote,
	RotateCcw,
	PanelRightClose,
	Rocket,
	ClipboardList,
	ScanLine,
	School,
	Search,
	SearchX,
	Send,
	Settings,
	Share,
	Shield,
	Signal,
	Trophy,
	Star,
	ArrowLeftRight,
	RefreshCw,
	Table,
	TableIcon,
	ThumbsDown,
	ThumbsUp,
	Triangle,
	Undo,
	ChevronsDownUp,
	ChevronsUpDown,
	EyeOff,
	ArrowUp,
	Upload,
	LayoutGrid,
	Globe,
	Workflow,
	ZoomIn,
	ZoomOut,
	ClockArrowUp,
	Archive,
	LineChart,
	Sparkles,
	LayoutList,
	Network,
	Menu,
	MoreHorizontal,
	Activity,
	Dot,
	LoaderCircle,
	Map,
	Hospital,
	Trash,
	Trash2,
	Radio,
	SquareCheck,
	MessageSquare,
	Compass,
	ChartColumn,
	FileClock,
	WandSparkles,
	Rows3,
	CalendarDays,
	CircleAlert,
	Minimize,
	NotebookText,
	Save,
	Undo2
} = WrappedIcons;
